.WelcomePage {
    width: 100%;
    height: 100vh;
    // background: #150558;
    overflow: hidden;

    &-wrapper {
        padding: 0 24px;

        &-head {
            img {
                width: 100%;
                height: calc(100vh - 350px);
                object-fit: contain;
            }
        }

        &-title {
            margin-bottom: 24px;

            h4 {
                font-size: 18px;
                text-align: center;
                color: var(--white);
                margin-bottom: 12px;
            }

            p {
                font-size: 14px; 
                color: var(--white);
                line-height: 22px;
                text-align: center;
            }
        }

        &-action {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 16px;
        }
    }
}
